

import React, { Component } from 'react';
import autosize from 'autosize';
import axios from '../../../../api/axios';


class VideoProposition extends Component {
  
  constructor(props) {
    super(props)
    


    this.state = {
      
      storedUserData :"",
      sujetdesc:'',
      formFields: {
        title: '',
        description: '',
        url:'',
        etat:'',
        sujet:''
    },
    id:'',
        data: [],
        sujet: [],
      };
      
  
}

    componentDidMount(){
     

      this.storedUserData =JSON.parse(localStorage.getItem('userData'));
       this.textarea.focus();
       autosize(this.textarea);
       let formFields = Object.assign({}, this.state.formFields);
    const id='';
       const { prop1 } = this.props;
       formFields.title = prop1.title;
       formFields.description = prop1.description;
       formFields.url = prop1.url;
       formFields.etat = prop1.etat;
       formFields.sujet = prop1.sujet.title;

       this.setState({id});

       this.setState({
        selectedOption:prop1.sujet.id
      });
       this.setState({formFields});
   
     axios.get(`sujet`)
     .then(res => {
       const sujet = res.data.sujet;
       this.setState({ sujet });
       
  

     }).catch(({response}) => {
     

   })



    }
   
  
    urlChangeHandler = (e) => {
      let formFields = Object.assign({}, this.state.formFields);
      formFields.url = e.target.value;
      this.setState({formFields});
    }



    titleChangeHandler = (e) => {
      let formFields = Object.assign({}, this.state.formFields);
      formFields.title = e.target.value;
      this.setState({formFields});
    }



    descriptionChangeHandler = (e) => {
      let formFields = Object.assign({}, this.state.formFields);
      formFields.description = e.target.value;
      this.setState({formFields});
    }

    handleOptionChange = (e) => {
      const index = e.target.selectedIndex;
      const el = e.target.childNodes[index]
      const option =  el.getAttribute('id'); 
      const desc =  el.getAttribute('value'); 
        this.setState({
          selectedOption:option
        });
        this.setState({
          sujetdesc:desc
        });
    }
   
 onSubmit = (e) => {
  e.preventDefault();
  try {
    const response =  axios.post(`modifer_proposition/${this.props.prop1.id}`,
        JSON.stringify({  title:this.state.formFields.title,
          description:this.state.formFields.description,
        
          url:this.state.formFields.url,
          sujet_id:this.state.selectedOption       }),
        {
         
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    )
    .then((response) => {
      window.location.reload();

    })

   
} catch (err) {
    if (!err?.response) {
    } else if (err.response?.status === 409) {
    } else {
    }
}


}
    render(){  
    

      const style = {
                maxHeight:'750px',
                minHeight:'75px',
                  resize:'none',
                  padding:'9px',
                  fontSize:'15px'};
        return (
          <div className='forum'>
              <div  className='propostion-title-etat  ' > 
                      <h1 className='mx-3  mb-4'>{this.state.formFields.title}</h1>
              
               </div>
               <div  className='propostion-title-etat  ' > 
               <h6 ><span >Sujet: </span>{this.state.formFields.sujet}</h6>
              
               </div>
              
          <form  onSubmit={this.onSubmit}  method="post">

          <div className="form-group  labellogin lienDrive">
            <label for="url">Sujet :</label>
            <p >Vous trouvez ci-dessous la liste des sujets correspondants à chaque catégorie de compétition.</p>
                          <select    ref={c=>this.textarea=c} className="form-control"  onChange={this.handleOptionChange}>
                          <option disabled selected>Choisissez un sujet</option>

              {       this.state.sujet.length != 0 && (this.state.sujet.map((sujet,index) => {
                                                          return    <option id={sujet.id} value={sujet.description}> {sujet.title}</option>
 
                                                         
                                                          }))}

              </select>
              {this.state.sujetdesc ? <div className='mt-3   sujetdescription'>
                                                    
                                                    {this.state.sujetdesc}</div>
                                              
                                          :''} 
          </div>
          {this.state.formFields.etat=="allowed"?
            <div className="form-group d-flex justify-content-center labellogin lienDrive">
          <iframe  height="300" width="500" className='' src={"//www.youtube.com/embed/"+this.state.formFields.url+"?rel=0"} scrolling="yes" sandbox="allow-same-origin allow-scripts"  ></iframe>
          </div>:''

          
          }
       
          
          <div className="form-group  labellogin lienDrive">
            <label for="url">Lien Drive</label>
            <p >Veuillez insérer ci-dessous le lien drive correspondant à votre vidéo dont la durée doit être de {this.props.prop1.type=="Debat"?"5":"10"}  minutes.</p>
            <input type="text"
             className="form-control"
              placeholder="Exemple: https://drive.google.com/file/d/zegsfdfefsdg/ssdgs" 
              id="url"
              name='url'
              required={true} 
              data-error="Please enter url" 
             
              defaultValue={this.state.formFields.url}
              onChange={this.urlChangeHandler}
              />
          </div>
          <div className="form-group labellogin lienDrive ">
            <label for="title " className="labellogin">Title</label>
            <input 
            type="text" 
            className="form-control" 
            placeholder="Title" 
            name='title'
            id="title"
            required={true} 
            data-error="Please enter title" 
            defaultValue={this.state.formFields.title}
            onChange={this.titleChangeHandler}

            
            />          </div>
          <div className="form-group labellogin   lienDrive">
          <label for="description">Description</label>

            <div>
            <textarea className="form-control"
 name='description'
 id="description"
 required={true} 
 data-error="Please enter description" 
 defaultValue={this.state.formFields.description}
 onChange={this.descriptionChangeHandler}



            style={style} 
         
            placeholder="type some text"
            rows={1}/>
          </div>          </div>
     


         
          <button  type='submit' class="btn  mb-2">Modifier</button>
    
          
          
        </form>
              </div>
        );
    }
}
export default  VideoProposition;