import { useLocation ,Navigate ,Outlet} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import AuthContext from "../../context/AuthProvider";
import { useEffect } from "react";

const RequireAuth=()=>{
    const {auth} = useAuth();
    const location = useLocation();
    const storedUserData = JSON.parse(localStorage.getItem('userData'));

    useEffect(() => {
  
    }, [])
    return (
        
        storedUserData ? <Outlet/>
        :<Navigate to="/login" state={{from:location}} replace />
        
        
    );
}

export default RequireAuth;