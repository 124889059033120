import React from 'react'
import { Link } from 'react-router-dom';

export const EmailVerifier = () => {
  return (
    <div class="verifierPage">
    <div class="emailverifier">
    <div class="success-checkmark">
      <div class="">
      <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" fill="#4caf50" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
</svg>
      </div>
    </div>
    <h2>L'e-mail a été vérifié avec succès !</h2>
  </div>

  <Link 
                            to="/" 
                            className="btn  not-found-btn"
                            
                        >
                           Retour à la page d'accueil
                        </Link>
  </div>
  )
}
