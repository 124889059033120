import React, {useState,useRef,useEffect}  from "react"
import axios from '../../api/axios';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function YouthLUCC() {
  const customeSlider = useRef();
    const [post, setpost] = useState([]);
    const [vid,uid]=useState('')
    const [title,utit]=useState('')

    useEffect(() => {
        async function getPost() {
               await axios.get(`getresource`)
              .then(res => {
                const post = res.data.resource;
          
               
    
                setpost(post);

                for (let p of post) {
            
                 if( p.type=="youth") {
                   uid(p.url)
                
                  break; 
                }

                }
             

              })
    
        }
        getPost() 
    
      
      }, []); 
  
      const settings = {
    

        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow:3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: false,
                dots: true,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1,
              },
            },
          ],
      };
      const previous = () => {
        customeSlider.current.slickNext();
      };
    
      const next = () => {
        customeSlider.current.slickPrev();
      };
    const renderVideo=()=>{
        return(
            <>
            <div className="video mb-3" >
             
            <iframe  className='youthvideo' src={"//www.youtube.com/embed/"+vid+"?rel=0"} frameBorder="0" allowFullScreen></iframe>
          </div>
          </>
        )
    }
  return (
    
    <div className='youtlucc ' id='YouthLUCC'>
        
          <h2 className='text-center m-5 '>YOUTH-LUCC</h2>


          <div className='container contenu'>
               {renderVideo()}
                      <div className="collection  ">
                    
      <Slider {...settings} ref={customeSlider} className="Slider">
                    
                    {       post.length != 0 && (post.map((post,index) => {
                                                                      return  post.type=="youth"?      <div>
                                                                      <div href="#!" className="collection-item"onClick={()=>{
                                                                      uid(post.url)
                                                                        
                                                                          utit(post.title)
                                                                      
                                                                      }}>       <div className=''>   
                                                                                  <iframe  className='youthvideoitem' src={"//www.youtube.com/embed/"+post.url+"?rel=0"} scrolling="yes" sandbox="allow-same-origin allow-scripts"  ></iframe>
                                                                  </div>    
                                                      
                                                      
                                                      
                                                                      </div></div>:''
                                                                      }))}      </Slider>
                      </div>
                      <div className='d-flex align-items-center custom-flex-containe '>
        <div  className="custom-center-content" > 
    <a   onClick={previous} class="previous round  mx-2" style={{ background: '#2C92D0' ,color:'white'}}>&#8249;</a>
<a  onClick={next} class="next round" style={{ background: '#2C92D0' ,color:'white'}}>&#8250;</a>
</div>
    </div>
          </div>
      </div>
  );
}

export default YouthLUCC;