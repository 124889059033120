import React, { useEffect }  from 'react'
import { useState } from 'react';
import  InfographieModif  from '../Common/Action/Modification/Infographie';
import  Footer from '../Common/Footer'
import  BlogProposition  from '../Common/Action/BlogProposition';
import { Link } from 'react-router-dom';
import NavBar from '../Navigation/navbar';
import VideoProposition from '../Common/Action/VideoDebatProposition';
import VideoCourtProposition from '../Common/Action/VideoCourtProposition';
import Infographie from '../Common/Action/Infographie'
import VideoPropositionmodif from '../Common/Action/Modification/VideoProposition';
import  BlogPropositionmodif  from '../Common/Action/Modification/BlogProposition';
import axios from '../../api/axios';
import { franc }from 'franc';


export const Profil = () => {
   const storedUserData = JSON.parse(localStorage.getItem("userData"))      ;
   const [action , setAction] = useState( );
   const [data, setData] = useState([ ]);
   const [email, setEmail] = useState("");
   const [age, setAge] = useState("");
   const [name, setUser] = useState("");
   const [numerotel, setNtel] = useState();
   const [image, setImage] = useState("");
   const [emailvalid, setEmailValid] = useState("");
   const [emailverifsend, setEmailemailverifsend] = useState("");

    useEffect(() => {
      async function getProfile() {
          await axios.get(`getUserData/${storedUserData.id}`)
          .then(({data}) => {
              setUser(data.user.name)
              setImage("https://dev.futurevisions.tn/"+data.user.image)
              setNtel(data.user.numerotel)
              setAge(data.user.age)
              setEmail(data.user.email)
              setEmailValid(data.user.email_verified_at)
          }).catch(({response}) => {
          
          })

            

      }
      getProfile() 
     
    }, []); 

    useEffect(() => {
        async function getProposition() {
            await axios.get(`showParticpantProposition/${storedUserData.id}`)
            .then(({data}) => {
       
                setData(data);
            
       

            }).catch(({response}) => {
            
            })

              

        }
        getProposition() 
       
      }, []); 

      const handleSubmit = async (e) => {
        console.log(storedUserData.email)
        e.preventDefault()
        try {
            const response = await axios.get(`verify_emailTow/${storedUserData.email}`,
               
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            ) .then((response) => {
              setEmailemailverifsend('L\'e-mail a été envoyé')
              });
       
           
                
        } catch (err) {
           
        }
    }
  return (
    <>
    <NavBar/>
    <div className=" container " id='Home'>
          
         <div className="d-table-profil">
             <div className="">
                 <div className="">
                     <div className=" profil row col-lg-16 d-flex align-items-center justify-content-center
"> {image!="https://dev.futurevisions.tn/null" ?
                              <div  id="avatardeimageprofil">   
                              <img src={image }className="profilimage2"/>  </div>:
                                 <div id="avatar" className="text-center mx-auto mb-4">
                                              <span>{name[0].toUpperCase()}</span>
                                </div>  } 
                    
      <div className='UserInfo col-lg-6 p-4 ms-2 align-items-center '>
                 <div className='  '>  
                 <div className='info ' >  
                 <h6>{storedUserData.name}</h6>
                <p className='emailstyle'>{storedUserData.email}</p>
                </div>
                <Link to='/profiledit'>
                <div className='pin ps-1'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="" className="bi bi-pen" viewBox="0 0 16 16">
  <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
</svg>

                </div></Link>
                </div>
                   <div className='row justify-content-center'>

<button className="btn tow " onClick={()=>setAction(<VideoCourtProposition/>)}>Court métrage </button>
<button className="btn  tow"onClick={()=>setAction(<BlogProposition/>)} >Blog </button>
<button className="btn  tow"onClick={()=>setAction(<Infographie/>)}>Infographie</button>
<button className="btn tow "onClick={()=>setAction(<VideoProposition/>)}>Débat</button>

</div>
{emailverifsend==''?
emailvalid==null?
  <div className='phraseclignotante ' onClick={handleSubmit}>
  <div>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="Crimson" class="bi bi-exclamation-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
</svg>
  </div>
<div className="text">Un e-mail de vérification a été envoyé à votre adresse e-mail. Veuillez vérifier votre boîte de réception.</div>
</div> :''


: <div className="emailenvoyer"> 
<div>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#4caf50" class="bi bi-check2-circle" viewBox="0 0 16 16">
  <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
  <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
</svg>

</div>

<div>
<p className='ms-1'>{emailverifsend}</p>
</div>

 </div>
  }


  </div>
                     </div>
                 </div>
                 
             </div>
          
         </div>
    

     </div>
     <div>
     <div className='  container '>
                     {action}
                     </div>  
             {       data.length !=0 ? (data.map((item,index) => {
              return    <div className= {franc(item.title)!="arb"?'container propositionitem  mb-5 ':"container propositionitem-arabe  mb-5"}>

                  <div class="comment-arrow"></div>
              <div  className='propostion-title-etat  ' > 
              
                        <h5 className='mx-3 sujettitle mb-4 '>{item.sujet.title}</h5>
               <div>
               <div>
                    {item.etat =="en cours" ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-clock-history mx-2" viewBox="0 0 16 16">
                            <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"/>
                            <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"/>
                            <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"/>
                            </svg>:<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cloud-check mx-2" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                              <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
                            </svg>
                        }
     
                        <b className='ms-3'>{item.etat=="allowed"?'Proposition approuvée':"En cours"}</b> 
                 </div>
     

               <div className="edit"  onClick={()=>setAction(item.type=="Infographie"?<InfographieModif   info={item}/>:item.type=="Blog"?<BlogPropositionmodif info={item}/>:<VideoPropositionmodif  key={index} prop1={item}/>)}>
               
                   <b className='  '>Modifer</b> 
               </div>  
              </div>
         
               </div>
                 <h5>{item.title} ({item.type})</h5>
              
                 <p  className=' m-4'>{item.type!="Blog" ? item.description :        <div
      dangerouslySetInnerHTML={{__html: item.text}}
    />}</p>
         
                      </div>

             
              })):  <div className=' container '>
              
           </div>} 
            
       <Footer/>
         </div>
     
     </>
  )
}
