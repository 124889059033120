import React, { useEffect ,useState} from 'react';

const CountdownCircles = ({ targetDate }) => {
  const [date, setdate] = useState('');




  /* qsqsqsqµ */ 

  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining(targetDate));
  
  useEffect(() => {
      const timer = setInterval(() => {
        setTimeRemaining(getTimeRemaining(targetDate));
      }, 1000);
  
      return () => {
        clearInterval(timer);
      };
    }, [targetDate]);


function getTimeRemaining(endTime) {
  const currentDate = new Date();
  
  const totalSeconds = Math.floor((endTime - Date.now()) / 1000);
  

  const days = Math.floor(totalSeconds / (3600 * 24));
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);




  return {
    days,
    hours,
    minutes,
    seconds
  };
}









/* qsqsqsqµ */ 
  useEffect(() => {
    const currentDate = new Date();
    setdate(currentDate)

    let dd = document.getElementById('dd');
    let hh = document.getElementById('hh');
    let mm = document.getElementById('mm');
    let ss = document.getElementById('ss');

    let dayDot = document.querySelector('.day_dots');
    let hrDot = document.querySelector('.hr_dots');
    let minDot = document.querySelector('.min_dots');
    let sec_dot = document.querySelector('.sec_dots');


    let x = setInterval(function () {
      let now = new Date().getTime();
      let countdown = new Date(targetDate).getTime();
      let distance = now - countdown;

      let d = Math.floor(distance / (1000 *60 *60 *24));
      let h = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let m = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let s = Math.floor((distance % (1000 * 60)) / 1000);

  
    }, 1000);

    return () => {
      clearInterval(x);
    };
  }, []);

  return (
    <div className='alltimetow'>
   
{date < targetDate ?  <p>     Il reste <span>{timeRemaining.days}</span>  jours, <span> {timeRemaining.hours}</span> heures,<span>{timeRemaining.minutes}</span> minutes et <span>{timeRemaining.seconds}</span> secondes avant le lancement des votes.
</p>
:''}
       
    </div>
  );
};

export default CountdownCircles;
