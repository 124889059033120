import { Outlet } from "react-router-dom"
import NavBar from "../Navigation/navbar"

const Layout = () => {
    return (
        <main className="App">
           
            <Outlet />
        </main>
    )
}

export default Layout