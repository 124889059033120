

import React, { Component, useEffect, useRef, useState } from 'react';
import autosize from 'autosize';
import axios from '../../../api/axios';
import { Link ,useLocation, useNavigate } from 'react-router-dom';
import Footer from '../Footer';

function Infographie(props) {
 const storedUserData =JSON.parse(localStorage.getItem('userData'));
 const inputRef = useRef(null);

 useEffect(() => {
   inputRef.current.focus();
 }, []);
 const navigate=useNavigate();

const  [title ,settitle]=useState('');
const [ description ,setdescription]=useState('');

const [value, setValue] = useState("");
  const [height, setHeight] = useState("auto");
  const [sujet, setSujet] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const  [sujetdesc ,setsujetdesc]=useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
    setHeight("auto");

    const newHeight = event.target.scrollHeight;
    setHeight(newHeight);
  };
 const  onSubmit = async (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append('title' , title)
      formData.append('description', description)
      formData.append('user_id' , storedUserData.id)
      formData.append('type', "Infographie")
      formData.append('image', image)
      formData.append('sujet_id', selectedOption)

      try {
      
        const response =  axios.post(`envoyer_proposition`,formData,
            {
             
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
        )
        .then((response) => {
              window.location.reload();

          navigate("/profil",{replace:true});



        })
 
       
    } catch (err) { 
        if (!err?.response) {
        } else if (err.response?.status === 409) {
        } else {
   
        }
    }

   
  }

  const [image, setImage] = useState([]);

  useEffect(() => {
  
    axios.get(`sujet`)
    .then(res => {
      const sujet = res.data.sujet;
      setSujet( sujet );
  

    }).catch(({response}) => {
    

  })

      }, []); 
  const handleOptionChange = (e) => {
        const index = e.target.selectedIndex;
        const el = e.target.childNodes[index]
        const option =  el.getAttribute('id'); 
        const desc =  el.getAttribute('value'); 
    setSelectedOption(option)
    setsujetdesc(desc)
      }   
        return (
          <>
          <div className='forum '>
          <form  onSubmit={onSubmit}  method="post" encType='multipart/form-data'>
          <div className="form-group  labellogin lienDrive">
            <label for="url">Sujet :</label>
            <p >Vous trouvez ci-dessous la liste des sujets correspondants à chaque catégorie de compétition.</p>
                          <select   ref={inputRef}  className="form-control"  onChange={handleOptionChange}>
                          <option disabled selected>Choisissez un sujet</option>

              {     sujet.length != 0 && (sujet.map((sujet,index) => {
                                                          return    <option id={sujet.id} value={sujet.description}> {sujet.title}</option>
 
                                                         
                                                          }))}

              </select>
              {sujetdesc ? <div className='mt-3   sujetdescription'>
                                                    
                                                    {sujetdesc}</div>
                                              
                                          :''}
          </div>
      
        <div className="form-group  labellogin lienDrive">
            <label for="infographie">Infographie</label>
            <p >Veuillez sélectionner l'image correspondant à votre œuvre en cliquant sur le bouton ci-dessous</p>
            <input type="file"  
           
            onChange={(event) => {setImage(event.target.files[0])}} 
            name="image" 
            required={true} 
            className="form-control mb-4" /> 

          </div>
          <div className="form-group labellogin lienDrive ">
            <label for="title " className="labellogin">Title</label>
            <input 
           
            type="text" 
            className="form-control" 
            placeholder="Title" 
            name='title'
            id="title"
            required={true} 
            data-error="Please enter title" 
            onChange={(e) => settitle(e.target.value)}
            value={title}

            
            />
          </div>
          <div className="form-group labellogin   lienDrive">
          <label for="description">Description</label>

            <div>
     
            <textarea
    name='description'
    id="description"
    required={true} 
    data-error="Please enter description" 
    placeholder="type some text"
    rows={1} defaultValue=""
    className="form-control"
    onChange={(e) => setdescription(e.target.value)}
    value={description}

      onInput={handleChange}
      style={{ height , resize:'none',
      padding:'9px',
      fontSize:'15px'}}
    />
           </div>          </div>
     


         
          
          
          <button  type='submit' class="btn  mb-2">Envoyer</button>

        </form>

              </div>
       
              </>
        );
    
}export default Infographie;