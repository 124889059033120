
import React, { useEffect }  from 'react'
import logo from '../../assets/images/perssone.jpg';
import NavBar from '../Navigation/navbar';

import { useState } from 'react';
import axios from '../../api/axios';

export const PropositionEtat = () => {
    const storedUserData =JSON.parse(localStorage.getItem('userData'));
    const [data, setData] = useState('');


    useEffect(() => {
        async function getProposition() {
           //katb3t fa9at parameter wa7ed li hwa route li defininah f api.php
            await axios.get(`showParticpantProposition/${storedUserData.id}`)
            .then(({data}) => {
       
                setData(data);

            }).catch(({response}) => {
            
            })

              

        }
        getProposition() 
       
      }, []); 
  return (
    <>
   <NavBar/>
   <div className="main-banner " id='Home'>
          
          <div className="d-table">
              <div className="d-table-cell">
                  <div className="container-fluid">
                      <div className=" profil row col-lg-16 d-flex align-items-center justify-content-center
 ">
                          {/* <p>Are you <span>ready</span> to attend?</p> */}
                          <div className='UserImage col-lg-3'>
                          {/* <img src={logo}className='profilimage'/> */}
                          <div id="avatar" className="text-center mx-auto mb-4">
          <span>K</span>
        </div>
     
       </div>
       <div className='UserInfo col-lg-6 p-4 ms-2 align-items-center '>
                  <div className='  '>  
                  <div className='info ' >  
                  <h5>{storedUserData.name}</h5>
                 <h6>{storedUserData.email}</h6>
                 <h6>{storedUserData.age} ans</h6></div>
                 <div className='pin ps-1'>
                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen" viewBox="0 0 16 16">
                 <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                </svg>
  </div>
                 </div>
              <div className='row justify-content-center'>
          
              </div>
 
   </div>
                      </div>
                  </div>
                  
              </div>
           
          </div>
          <div className='container '>
          <div className='etat  '>
      <h6>{data.title}</h6>
      <div className='etattype'>
         icon
         In progress
      </div>
          </div> 
           </div>
      </div>
    </>
  )
}
