import React, { useEffect ,useState} from 'react';

const CountdownCircles = ({ targetDate }) => {




  /* qsqsqsqµ */ 

  const [timeRemaining, setTimeRemaining] = useState(getTimeRemaining(targetDate));
  
  useEffect(() => {
      const timer = setInterval(() => {
        setTimeRemaining(getTimeRemaining(targetDate));
      }, 1000);
  
      return () => {
        clearInterval(timer);
      };
    }, [targetDate]);


function getTimeRemaining(endTime) {
  const currentDate = new Date();
  
  const totalSeconds = Math.floor((endTime - Date.now()) / 1000);
  

  const days = Math.floor(totalSeconds / (3600 * 24));
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);




  return {
    days,
    hours,
    minutes,
    seconds
  };
}









/* qsqsqsqµ */ 
  useEffect(() => {
 

    let dd = document.getElementById('dd');
    let hh = document.getElementById('hh');
    let mm = document.getElementById('mm');
    let ss = document.getElementById('ss');

    let dayDot = document.querySelector('.day_dots');
    let hrDot = document.querySelector('.hr_dots');
    let minDot = document.querySelector('.min_dots');
    let sec_dot = document.querySelector('.sec_dots');


    let x = setInterval(function () {
      let now = new Date().getTime();
      let countdown = new Date(targetDate).getTime();
      let distance = now - countdown;

      let d = Math.floor(distance / (1000 *60 *60 *24));
      let h = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let m = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let s = Math.floor((distance % (1000 * 60)) / 1000);

      dd.style.strokeDashoffset = 440 - (440 * d) / 365;
      hh.style.strokeDashoffset = 440 - (440 * h) / 24;
      mm.style.strokeDashoffset = 440 - (440 * m) / 60;
      ss.style.strokeDashoffset = 440 - (440 * s) / 60;

      dayDot.style.transform = `rotateZ(${d * 0.986}deg)`;
      hrDot.style.transform = `rotateZ(${h * 15}deg)`;
      minDot.style.transform = `rotateZ(${m * 6}deg)`;
      sec_dot.style.transform = `rotateZ(${s * 6}deg)`;
    }, 1000);

    return () => {
      clearInterval(x);
    };
  }, []);

  return (
    <div className='alltime'>
      <div id="time">
        <div className='circle' style={{ "--clr": "#FEC053" }}>
          <div className='dots day_dots'></div>
          <svg>
            <circle cx="70" cy="70" r="70"></circle>
            <circle cx="70" cy="70" r="70" id="dd"></circle>
          </svg>
          <div id='days'>
          {timeRemaining.days}
            <br /> <span className='label mt-2'>Jours</span>
          </div>
        </div>

        <div className='circle' style={{ "--clr": "#F2203E" }}>
          <div className='dots hr_dots'></div>
          <svg>
            <circle cx="70" cy="70" r="70"></circle>
            <circle cx="70" cy="70" r="70" id="hh"></circle>
          </svg>
          <div id='hours'>
          {timeRemaining.hours}
          <br /> <span className='label  mt-2'>Heures</span>
          </div>
        </div>
        </div>

        <div id="time">
        <div className='circle' style={{ "--clr": "#B279A8" }}>
          <div className='dots min_dots'></div>
          <svg>
            <circle cx="70" cy="70" r="70"></circle>
            <circle cx="70" cy="70" r="70" id="mm"></circle>
          </svg>
          <div id='minutes'>
          {timeRemaining.minutes}
          <br /> <span className='label  mt-2'>Minutes</span>
          </div>
        </div>

        <div className='circle' style={{ "--clr": "#2C92D0" }}>
          <div className='dots sec_dots'></div>
          <svg>
            <circle cx="70" cy="70" r="70"></circle>
            <circle cx="70" cy="70" r="70" id="ss"></circle>
          </svg>
          <div id='seconds'>
          {timeRemaining.seconds}
          <br /> <span className='label  mt-2'>Seconds</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountdownCircles;
