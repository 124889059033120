import React  from 'react'
import logo from '../../assets/images/perssone.jpg';
import { useState , useEffect ,useRef} from 'react';
import NavBar from '../Navigation/navbar';
import axios from '../../api/axios';
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from '../Common/Footer';
import { useNavigate } from 'react-router-dom';

const PROFILEEDIT_URL = '/modifier_profil';

const USER_REGEX = /^[A-Za-z]+(?: [A-Za-z]+){0,3}$$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const AGE_REGEX = /^(1[8-9]|[2-9][0-9])$/;
const PHN_REGEX = /^\d{8}$|^216\d{8}$|^0\d{7}$/;
export const ProfilEdit = () => {
    const storedUserData = JSON.parse(localStorage.getItem('userData'));
    const id=storedUserData.id;

    const userRef = useRef();
    const errRef = useRef();
    const [data, setData] = useState();

    const [email, setEmail] = useState(storedUserData.email);
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [age, setAge] = useState(storedUserData.age);
    const [validAge, setValidAge] = useState(false);
    const [ageFocus, setAgeFocus] = useState(false);

    const [name, setUser] = useState(storedUserData.name);
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [lastname, setLastname] = useState('');
    const [validlastname, setValidLastname] = useState(false);
    const [lastnameFocus, setlastnameFocus] = useState(false);


    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [csrfToken, setCsrfToken] = useState('');

    const [numerotel, setNtel] = useState(storedUserData.numerotel);

    const [validNtel, setValidNtel] = useState(false);
    const [NtelFocus, setNtelFocus] = useState(false);


    const [image, setImage] = useState("");
    const [imagelocale, setImageLocale] = useState();

    const navigate=useNavigate();

    useEffect(() => {
        
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(name));
    }, [name])

     useEffect(() => {
        setValidLastname(USER_REGEX.test(lastname));
    }, [lastname])

     useEffect(() => {
      setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    useEffect(() => {
        setValidNtel(    PHN_REGEX.test(numerotel));
      }, [numerotel])

    useEffect(() => {
      setValidAge(AGE_REGEX.test(age));
    }, [age])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [name, pwd, matchPwd])

    useEffect(() => {

      async function fetchCsrfToken() {
        const response = await axios.get('/csrf-token');
        setCsrfToken(response.data.csrfToken);
      }
      fetchCsrfToken();
    }, []);

    useEffect(() => {
        async function getProposition() {
            await axios.get(`getUserData/${storedUserData.id}`)
            .then(({data}) => {
               
                setImageLocale("https://dev.futurevisions.tn/"+data.user.image)
                
            }).catch(({response}) => {
            
            })
        }
        getProposition() 
       
      }, []); 

    const handleFileChange = (e) => {
          const file = e.target.files[0];
          const reader = new FileReader();
          setImage(file)
          reader.onloadend = function () {
            setImageLocale(reader.result);
          };
          if (file) {
            reader.readAsDataURL(file);
          }
        };
    
    const onSubmit = async (e) => {
        const formData = new FormData();
        formData.append('email' , email)
        formData.append('name', name)
        formData.append('image' ,image)
        formData.append('age' ,age)
        formData.append('numerotel',numerotel)
        e.preventDefault();   

        try {
            const response = await axios.post(`modifier_profil/${id}`,formData,
                {
                    headers: {      'Content-Type': 'multipart/form-data', },
                    withCredentials: true
                }
            )
            .then((response) => {

            
                
       
                const data={id,email,numerotel,age,name,image}
          

                localStorage.setItem('userData', JSON.stringify(data));
                       storedUserData.email=email
                
                
                navigate("/profil",{replace:true});


         
            })
     
            // setSuccess(true);
           
        } catch (err) {
         
            if (!err?.response) {
                setErrMsg('No Server Response');
            

            } else if (err.response?.status === 409) {
                setErrMsg(err);

            } else {
                setErrMsg(err)
            }
        }
    }

  return (
    <>
    
    
    <NavBar/>
    <div className="main-banner   mb-5" id='Home'>
          
         <div className="d-table-profil">
             <div className="">
                 <div className="">
                     <div className=" profil row col-lg-16 d-flex align-items-center justify-content-center
">
                         {/* <p>Are you <span>ready</span> to attend?</p> */}
                         
                                <div className='UserInfo col-lg-6 p-4 ms-2 align-items-center '>
                                <form onSubmit={onSubmit}  method="post"        formEncType='multipart/form-data'>

                                            <div className=' col-lg-11   align-items-center' >  

                                                                                                <div className='UserImage col-lg-5 '>
                                                                                               
                                                                                                {imagelocale!="https://dev.futurevisions.tn/null" ?<div id='avatar'>  
                                                                                                { <img src={imagelocale }className='profilimage2'/> } </div> :
                                                                                                            <div id="avatar" className="text-center mx-auto mb-4">
                                                                                                            <span>{storedUserData.name[0].toUpperCase()}</span>
                                                                                                        </div>  }               
                                                            
                                                                          
                                                                            <div className='picker'>
                                                                            <input

                                                                                    onChange={handleFileChange} 
                                                                                
                                                                                        type="file"
                                                                                        id="select-image"
                                                                                        style={{ display: 'none' }}
                                                                                    />
                                                                                    <label htmlFor="select-image">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black" class="bi bi-camera" viewBox="0 0 16 16">
                                                                                        <path d="M15 12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1.172a3 3 0 0 0 2.12-.879l.83-.828A1 1 0 0 1 6.827 3h2.344a1 1 0 0 1 .707.293l.828.828A3 3 0 0 0 12.828 5H14a1 1 0 0 1 1 1v6zM2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2z"/>
                                                                                        <path d="M8 11a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zM3 6.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"/>
                                                                                        </svg>
                                                                                    </label>
                                                                                    </div>
                                                                                                    </div>

                                                                                                    
                                                                                                <div className="form-group labellogin">
                                                                                                <label  htmlFor="name">Nom et Prénom 
                                                            <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                                                            <FontAwesomeIcon icon={faTimes} className={validName || !name ? "hide" : "invalid"} />
                                                            
                                                                                                </label>
                                                            
                                                            <input  type="text"
                                                                    id="name"
                                                                    ref={userRef}
                                                                    autoComplete="off"
                                                                    onChange={(e) => setUser(e.target.value)}
                                                                    value={name}
                                                                    required
                                                                    aria-invalid={validName ? "false" : "true"}
                                                                    aria-describedby="uidnote"
                                                                    onFocus={() => setUserFocus(true)}
                                                                    onBlur={() => setUserFocus(false)}
                                                                        className="form-control"
                                                                        placeholder="First Name"/>
                                                                            <p id="uidnote" className={userFocus && name && !validName ? "instructions" : "offscreen"}>
                                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                                    4 to 24 characters.<br />
                                                                    Must begin with a letter.<br />
                                                                    First name and last name
                                                                </p>                                          </div>
                                                                                            
                                                                                                <div className="form-group labellogin  ">
                                                                                                <label htmlFor="name">Age
                                                            <FontAwesomeIcon icon={faCheck} className={validAge ? "valid" : "hide"} />
                                                            <FontAwesomeIcon icon={faTimes} className={validAge || !age ? "hide" : "invalid"} />
                                                            
                                                            </label>
                                                            <input type="number"                        
                                                                
                                                                    autoComplete="off"
                                                                    onChange={(e) => setAge(e.target.value)}
                                                                    value={age}
                                                                    required
                                                                    aria-invalid={validAge ? "false" : "true"}
                                                                    aria-describedby="uidnote"
                                                                    onFocus={() => setAgeFocus(true)}
                                                                    onBlur={() => setAgeFocus(false)}
                                                                        className="form-control" 
                                                                        placeholder="Age" 
                                                                        id="age"/>
                                                                            <p id="uidnote" className={ageFocus && age && !validAge ? "instructions" : "offscreen"}>
                                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                                    Please enter a valid age between 18 and 99.
                                                    <br />
                                                                    
                                                                </p>     </div>    <div className="form-group labellogin  ">
         <label htmlFor="username">Numéro de téléphone
         <FontAwesomeIcon icon={faCheck} className={validNtel ? "valid" : "hide"} />
         <FontAwesomeIcon icon={faTimes} className={validNtel || !numerotel ? "hide" : "invalid"} />
         
         </label>
         <input type="text"                        
              
                   autoComplete="off"
                   onChange={(e) => setNtel(e.target.value)}
                   value={numerotel}
                   required
                   aria-invalid={validNtel ? "false" : "true"}
                   aria-describedby="uidnote"
                   onFocus={() => setNtelFocus(true)}
                   onBlur={() => setNtelFocus(false)}
                    className="form-control" 
                    placeholder="exemple: +216 99 999 999 " 
                    id="Ntel"/>
                         <p id="uidnote" className={NtelFocus &&  numerotel && !validNtel? "instructions" : "offscreen"}>
                   <FontAwesomeIcon icon={faInfoCircle} />
                   Please enter a valid tel.
<br />
                 
               </p>
       </div>
                                                                                                {/* <div className="form-group labellogin">
                                                                                                <label for="name">Email
                                                            <FontAwesomeIcon icon={faCheck} className={validEmail ? "valid" : "hide"} />
                                                            <FontAwesomeIcon icon={faTimes} className={validEmail || !email ? "hide" : "invalid"} />
                                                            
                                                            
                                                            </label>
                                                            <input type="email"
                                                                autoComplete="off"
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                    value={email}
                                                                    required
                                                                    aria-invalid={validEmail ? "false" : "true"}
                                                                    aria-describedby="uidnote"
                                                                    onFocus={() => setEmailFocus(true)}
                                                                    onBlur={() => setEmailFocus(false)}
                                                                        className="form-control" 
                                                                        placeholder="Email" id="email"/>
                                                                                <p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                                    Please enter a valid email address in the format<br />
                                                                        name@example.com.<br />
                                                                </p>  </div> */}
                                                                                                
                                                                                                <div className='row justify-content-center'>
                                                                                            <button class="btn tow "  type="submit"  >Modifer </button>
                                                                                            <p id="uidnote" className={success  ? "valid" : "offscreen"}>
                                                                    <FontAwesomeIcon icon={faCheck} />
                                                                    Updated<br />
                                                                    
                                                                </p> 
                                                                                            </div>

                                 
                                
                                            </div>
                                           </form>

                                    </div>
                                  
                     </div>
                 </div>
                 
             </div>
          
         </div>
       
     </div>
     
     <Footer/>
     </>
  )
}
