import React, {Component, useState,useEffect} from 'react';

import logo from '../../assets/images/Fichier 3icon gallery.png';
import { HashLink as Link  } from 'react-router-hash-link';
import useAuth from '../../hooks/useAuth';
import { BsTypeH1 } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import ProfileAvatar from './ProfileAvatar';




function NavBar(props)  {
  const {auth} = useAuth();

  const  [collapsed ,setCollapsed]=useState(true);
  const  [isOpen  , setIsOpen]=useState(false);
  const storedUserData = localStorage.getItem("userData") ??JSON.parse(localStorage.getItem("userData"))      ;
  const [screenSize, setScreenSize] = useState({ width: 0, height: 0 });
  useEffect(() => {
    const handleResize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };

    // Set initial screen size
    handleResize();

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleNavbar = () => {
    setCollapsed( !collapsed,)

}
useEffect(() => {
  let elementId = document.getElementById("navbar");
  document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
          elementId.classList.add("is-sticky");
          window.history.pushState("", document.title, window.location.pathname);
      } else {
          elementId.classList.remove("is-sticky");
      }
  });
  window.scrollTo(0, 0);
}, [])

const toggleOpen = () => setIsOpen(!this.state.isOpen );


const onRouteChanged = () => {
  setIsOpen( !this.state.isOpen );
}
const navigate=useNavigate();
const logout = () => {
  localStorage.removeItem('userData');
  navigate('/login');

};

const classOne = collapsed ? 'collapse navbar-collapse ' : 'collapse navbar-collapse show';
const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';


 
    return(

      <header id="header" className="header-area">
             
  <div id="navbar" className="elkevent-nav ">
    
           <nav className="navbar navbar-expand-md navbar-dark ">
             
                        <div className="container-fluid mx-5">
                        <img  className="logo" src={logo} alt="logo" />

                        <button 
                         onClick={toggleNavbar}
                                className={classTwo}
                                type="button" 
                                data-toggle="collapse" 
                                data-target="#navbarSupportedContent" 
                                aria-controls="navbarSupportedContent" 
                                aria-expanded="false" 
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>

                          
                            <div className={classOne} id="navbarSupportedContent">
                          
                            <ul className="navbar-nav ms-auto d-flex justify-content-start">
                                    <li className="nav-item">
                                    <Link  to="/#" smooth>Accueil</Link>
                                    


                                    </li>
                                    <li className="nav-item">

                                    <Link  to="/#YouthLUCC" smooth>Youth LUCC</Link>
                                    
                                    </li>
                                    <li className="nav-item">
                                    <Link  to="/#KidsLUCC"  smooth>Kids LUCC</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link  to="/#Parti"  smooth>Participants gallery</Link>
                                    </li>
                                    <li className='nav-item mt-3 ' >
                                                        
                                 
                                                
                                    {screenSize.width>812 ?storedUserData!=null  ?   <ProfileAvatar/>:'':
                                    storedUserData!=null  ? 
                                    <div>   
                                                <div className='d-grid d-flex justify-content-center'>
                                    
                                    <Link to="/profil">
                                                  <svg className='settingicon' xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
  </svg>
                                    </Link>
                                      </div>
                                      <div className="d-grid d-flex justify-content-center mt-3 button-logout profil-menu">

                                  <p className="logout-profil-button ms-1 " onClick={logout}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
                            <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
                                    </svg> 
                                      <small className=''>Se déconnecter </small>
                                    </p>
                                  </div>
                        
                                
                                  </div>:''
                                   
                        }      
                
                                                        </li>
                                          

                           
                                </ul>
                             
                                    <ul>
                               
                          
                                        
                          

                                     
                                    </ul>
                              
                            </div>
                        </div>
                    </nav>
                  
                    </div>
            </header>
       

    );
  
}



export default NavBar;
