import axios from '../../api/axios';
import logo from '../../assets/images/main.jpg';
import { Login } from "../pages/Login";

import React from 'react'
import NavBar from '../Navigation/navbar';
import { Link } from 'react-router-dom';
import { useContext ,useEffect,useState} from 'react';
import useAuth from '../../hooks/useAuth.js';


  function Main (){
    const {auth} = useAuth();
    
    const storedUserData = localStorage.getItem("userData") ??JSON.parse(localStorage.getItem("userData"))      ;

    const [info, setInfo] = useState([]);




  useEffect(() => {

    async function getPost() {
      axios.get(`getinfo`)
      .then(res => {
        const info = res.data.info[0];
      setInfo(info );

      })

    }
    getPost() 

  
  }, 
  
  []); 



      return (
        <>
        <div className="main-banner item-bg2" id='Home'>
        <div className="shape1">
         </div>
         <div className="shape2 ">
         </div>
         <div className="shape3 ">
         </div>
         <div className="shape4">
         </div>
    <div className="d-table">
        <div className="d-table-cell">
            <div className="container">
                <div className="main-banner-content banner-content-center row">
                   
                    <div className='section-title   col-lg-6 '>
                    <h1> 
                      {info.Titre??info.Titre}
                     <br />
                     
                      </h1>
                      <p>
                        {info.description??info.description} 
                         <br></br>
<span>                        

 {info.description_mini??info.description_mini}
                </span> </p>
              <Link  to={storedUserData!=null   ?  "/profil" :"/login"} className="btn  mt-5 ">Participer</Link>
 </div>
 <div className='mainImage col-lg-6  '>
<img src={logo}className=' '/>
</div>
                </div>
            </div>
        </div>
    </div>

   
</div></>
      );
  
}  export default Main;