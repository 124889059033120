import React from 'react'
import Footer from '../Common/Footer'
import { KidsLucc } from '../Home/KidsLucc'
import YouthLUCC from '../Home/YouthLucc'
import Main from '../Home/Main'
import NavBar from '../Navigation/navbar';
import { ParticipantCrsoul } from '../Home/ParticipantCrsoul'



class HomePage extends React.Component {
    
    render() {
  
  
      return(
        <React.Fragment>
             <NavBar/>
        <Main/>
       <YouthLUCC/>
       <KidsLucc/>
      <ParticipantCrsoul/>
       <Footer/>
 
    </React.Fragment>
      );
    }
  }
  

  
  export default HomePage;