import { useRef, useState, useEffect, useContext } from 'react';
import axios   from "../../api/axios.js";
import useAuth from '../../hooks/useAuth.js';
import logo from '../../assets/images/Fichier 3icon gallery.png';
import { Link ,useLocation, useNavigate } from 'react-router-dom';
import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from 'react-router-dom';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const LOGIN_URL = '/login';
const USER_REGEX = /^[A-z][A-z]+ [A-z][A-z]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const AGE_REGEX = /^(1[5-9]|[2-9][0-9])$/;
const PHN_REGEX = /^\d{8}$|^216\d{8}$|^0\d{7}$/;
const REGISTER_URL = '/register';

export const UpdatePassword = () => {
    const navigate=useNavigate();
    const location =useLocation();
    const from=location.state?.from?.pathname||"/";
    const { setAuth } = useAuth();

  const userRef = useRef();
    const errRef = useRef();
    const { id } = useParams();


    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [age, setAge] = useState('');

    const [validAge, setValidAge] = useState(false);
    const [ageFocus, setAgeFocus] = useState(false);


    const [Ntel, setNtel] = useState('');

    const [validNtel, setValidNtel] = useState(false);
    const [NtelFocus, setNtelFocus] = useState(false);

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [lastname, setLastname] = useState('');
    const [validlastname, setValidLastname] = useState(false);
    const [lastnameFocus, setlastnameFocus] = useState(false);


    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])


     useEffect(() => {
        setValidLastname(USER_REGEX.test(lastname));
    }, [lastname])

    useEffect(() => {
      setValidEmail(EMAIL_REGEX.test(email));
    }, [email])
     useEffect(() => {
      setValidNtel(    PHN_REGEX.test(Ntel));
    }, [Ntel])

    useEffect(() => {
      setValidAge(AGE_REGEX.test(age));
    }, [age])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd])

    useEffect(() => {

      async function fetchCsrfToken() {
        const response = await axios.get('/csrf-token');
        setCsrfToken(response.data.csrfToken);
      }
      fetchCsrfToken();
    }, []);
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`updateResetPassword/${id}`,
            JSON.stringify({
             
                password: pwd,
                
               }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            ) .then((response) => {
                if(response.data['message']=='The provided credentials are incorrect'){ setErrMsg('Missing Email or Password');}
         else{
            const data=response?.data?.user
            
            localStorage.setItem('userData', JSON.stringify(data));
        

      setAuth({ data});
     setUser('');
    setPwd('');
   navigate(from,{replace:true});
         }
           
              });
           
                
        } catch (err) {
           
        }
    }
    const [showPassword, setShowPassword] = useState(false);

const togglePasswordVisibility = () => {
  setShowPassword(!showPassword);
};
const [showPassword2, setShowPassword2] = useState(false);

const togglePasswordVisibility2 = () => {
  setShowPassword2(!showPassword2);
};
  return (
<div className="content-login  row">
                 <div className='logintitle   col-lg-6 '>
                    <div className='logintitle-minisection '>
                 <img src={logo}className='logoImagelogin'/>
            





                    <h1>Bienvenue de retour sur <b>Youth lucc!</b> </h1>
                    <div className="luucshapelogin">
                        <img src={require("../../assets/images/abc-removebg-preview.png")} className=" luucshape" alt="shape4" />
                    </div> 
                    </div>            
                    </div>
                    <div className='loginsection col-lg-4 '>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

                        <h4>Choisissez un nouveau mot de passe

</h4>
<div>
    <p>Créez un mot de passe d’au moins 6 caractères. Un mot de passe fort est une combinaison de lettres, de chiffres et de signes de ponctuation.</p>
</div>
                        <form action="#" method="post" onSubmit={handleSubmit}>
  
                        <div className="form-group labellogin  ">
         <label for="password " className="labellogin">Mot de passe
         <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                   <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
         
         </label>
         <div  className="form-controlmotdepasse">
         <input   type= {showPassword ? "text":"password"}
                   id="password"
                   ref={userRef}
                   onChange={(e) => setPwd(e.target.value)}
                   value={pwd}
                   required
                   aria-invalid={validPwd ? "false" : "true"}
                   aria-describedby="pwdnote"
                   onFocus={() => setPwdFocus(true)}
                   onBlur={() => setPwdFocus(false)}
                   className="form-control-motdepasse-input"
                    
                    />
<span onClick={togglePasswordVisibility} className='lesapan'>
      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
    </span></div>
<p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                   <FontAwesomeIcon icon={faInfoCircle} />
                   8 à 24 caractères.<br />
                   Doit inclure des lettres en majuscules et en minuscules, un chiffre et un caractère spécial.<br />
                   Caractères spéciaux autorisés : <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
               </p>
       </div>

       <div className="form-group labellogin mb-4  ">
         <label htmlFor="confirm_pwd"className="labellogin">Confirmer mot de passe</label>
         <div  className="form-controlmotdepasse">
         <input 
         
         type= {showPassword2 ? "text":"password"}
         id="confirm_pwd"
         onChange={(e) => setMatchPwd(e.target.value)}
         value={matchPwd}
         required
         aria-invalid={validMatch ? "false" : "true"}
         aria-describedby="confirmnote"
         onFocus={() => setMatchFocus(true)}
         onBlur={() => setMatchFocus(false)}
         className="form-control-motdepasse-input"
          />
          <span onClick={togglePasswordVisibility2} className='lesapan'>
      <FontAwesomeIcon icon={showPassword2 ? faEyeSlash : faEye} />
    </span></div>
            <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                   <FontAwesomeIcon icon={faInfoCircle} />
                   Doit correspondre au premier champ de saisie du mot de passe.
               </p>
       </div>


                                
<div className='btn-continuer-annuler'>
<Link className="btn annulerbttn  ms-3 mb-2 w-100  w-100 " to='/login'>Annuler</Link>
   <button className="btn ms-3 mb-2 w-100" type='submit'>Continuer</button>

</div>

                                    
                            </form>
                            <div>
       <p className='signupOrSignin'>

       <Link  to='/register' >Ce connecter avec le mot de passe</Link> 
       </p></div>
       <div className='logominidev'>
       <img src={logo}className='logomini'/>

       </div>
        
                    </div>
                

                                </div>
  )
}
export default UpdatePassword;


