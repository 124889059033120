import { useRef, useState, useEffect, useContext } from 'react';
import axios   from "../../api/axios.js";
import useAuth from '../../hooks/useAuth.js';
import logo from '../../assets/images/Fichier 3icon gallery.png';
import { Link ,useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthProvider.js';


const LOGIN_URL = '/login';
function Login() {
    const { userdata, login } = useContext(AuthContext);

   const { setAuth } = useAuth();

    const navigate=useNavigate();
    const location =useLocation();
    const from=location.state?.from?.pathname||"/";


    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
        
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({
                  email: user,
                  password: pwd,
                  
                 }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            ) .then((response) => {
                if(response.data['message']=='The provided credentials are incorrect'){ setErrMsg('Missing Email or Password');}
         else{
            const data=response?.data?.user
           

            localStorage.setItem('userData', JSON.stringify(data));
         


      setAuth({ data});
     setUser('');
    setPwd('');
   navigate(from,{replace:true});
         }
           
              });
       
           
                
        } catch (err) {
       
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Email or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
            
                setErrMsg(err);
            }
            errRef.current.focus();
        }
    }
  return (
<div className="content-login  row">
                 <div className='logintitle   col-lg-6 '>
                    <div className='logintitle-minisection '>
                 <img src={logo}className='logoImagelogin'/>
            





                    <h1>Bienvenue de retour sur <b>Youth lucc!</b> </h1>
                    <div className="luucshapelogin">
                        <img src={require("../../assets/images/abc-removebg-preview.png")} className=" luucshape" alt="shape4" />
                    </div> 
                    </div>            
                    </div>
                    <div className='loginsection col-lg-4 '>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

                        <h4>Login</h4>
                        <form action="#" method="post" onSubmit={handleSubmit}>
                                    <div className="form-group labellogin">
                                    <label htmlFor="username">Email</label>
                                    <input 
                                    className="form-control" 
                                    type="email"
                                    id="email"
                                    ref={userRef}
                                    autoComplete="off"
                                    onChange={(e) => setUser(e.target.value)}
                                    value={user}
                                    required
                                    
                                    
                                    />
                                    </div>
                                    <div className="form-group labellogin  ">
                                    <label htmlFor="password " className="labellogin">Mot de passe</label>
                                    <input 
                                    className="form-control" 
                                    type="password"
                                    id="password"
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    required
                                    />
                                    </div>
                                    
                                    <div className="d-sm-flex mb-4 align-items-center">
                                    <span className="ml-auto"><Link to='/ResetPassword' className="forgot-pass">Mot de passe oublié ?




                    </Link></span> 
                                    </div>


                                

                                    <button className="btn  mb-2 w-100" type='submit'>Se connecter</button>

                                    
                            </form>
                            <div>
       <p className='signupOrSignin'>
Vous n'avez pas de compte?
       <Link  to='/register' >Créer un compte</Link> 
       </p></div>
       <div className='logominidev'>
       <img src={logo}className='logomini'/>

       </div>
        
                    </div>
                

                                </div>
  )
}
export default Login;