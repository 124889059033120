import logo from './logo.svg';
import  HomePage  from './Components/pages/HomePage';
import NavBar from './Components/Navigation/navbar';
import './assets/css/bootstrap.min.css';

import './assets/css/style.css';
import { KidsLucc } from './Components/Home/KidsLucc';
import  Login  from './Components/pages/Login';
import { SingUp } from './Components/pages/SingUp';
import { Profil } from './Components/pages/Profil';
import { ProfilEdit } from './Components/pages/ProfilEdit';
import { PropositionEtat } from './Components/pages/PropositionEtat';
import React from 'react'
import { Routes   ,Route } from 'react-router-dom';
import  Layout  from "./Components/Common/Layout";
import RequireAuth from './Components/pages/RequireAuth';
import NotFound from './Components/pages/NotFound';
import Footer from './Components/Common/Footer';
import { Propositiondetails } from './Components/pages/Propositiondetails';
import { EmailVerifier } from './Components/EmailVerifier';
import { EmailActive } from './Components/EmailActive';
import ResetPassword from './Components/pages/ResetPassword';
import ResetcCrypCode from './Components/pages/ResetcCrypCode';
import UpdatePassword from './Components/pages/UpdatePassword';



function App() {

  return (
<>  
 <Routes>

  <Route path='/' element={<Layout/>} >
    {/*public routes  */ }
    <Route path='register' element={<SingUp/>}/>
    <Route path='login' element={<Login/>} />
    <Route path='/' element={<HomePage/>}/>

    <Route path='ResetPassword' element={<ResetPassword/>} />
    

    <Route path='ResetcCrypCode/:id' element={<ResetcCrypCode/>} />

    <Route path='Propositiondetails/:id' element={<Propositiondetails/>} />
    <Route path='UpdatePassword/:id' element={<UpdatePassword/>} />
    <Route element={<RequireAuth/>}>
    <Route path='profiledit' element={<ProfilEdit/>} />
    <Route path='PropositionEtat' element={<PropositionEtat/>} />
    <Route path='EmailVérifie' element={<EmailVerifier/>} />


    <Route path='EmailActive' element={<EmailActive/>} />

    <Route path='/profil' element={<Profil/>}/>
    
    </Route>
    <Route path='*' element={<NotFound/>} />


  </Route>
  
 </Routes>
 </>
  );
}

export default App;
