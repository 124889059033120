import React, { useEffect }  from 'react'
import { useState } from 'react';
import axios from '../../api/axios';
import NavBar from '../Navigation/navbar';
import Footer from '../Common/Footer';
import { useNavigate } from 'react-router-dom';
import { franc }from 'franc';

import { useParams } from 'react-router-dom';
import { LinkedinShareButton  ,
    
  FacebookShareButton,

  TwitterShareButton,
 
  




} from 'react-share';
export const Propositiondetails = (props)  => { 
  const [showFullText, setShowFullText] = useState(false);
  const storedUserData = JSON.parse(localStorage.getItem('userData'));
  const navigate=useNavigate();
  const [vote, setvote] = useState();
  const [data, setData] = useState('');
  const [proposition, setProposition] = useState('');
  const [userdetails, setUserdetails] = useState('');
  const [votenb, setvotenb] = useState();
  const [date, setdate] = useState('');

  const [text, settext] = useState('');
  const [name, setname] = useState('');
  const [title, settitle] = useState('');
  const { id } = useParams();
  const [nbvote, setnbvote] = useState('');
  const [voteDate, setvoteDate] = useState('');
  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };
 
  useEffect(() => {

    const currentDate = new Date();
    setdate(currentDate)
    async function getProposition() {
      //katb3t fa9at parameter wa7ed li hwa route li defininah f api.php
       await axios.get(`gettemps`)
       .then((temps) => {


        const dateObjvote = new Date(temps.data.temps[0].temps_lancement_vote);

      
    
        setvoteDate(dateObjvote)
       }).catch(({response}) => {
   
       })

         

   }
   getProposition() 
  
 }, []); 

useEffect(() => {

  axios.get(`showtProposition/${id}`)
  .then((item) => {
  
        setProposition(item.data)
        // settitle(proposition.title)
        // setname(proposition.user.name)

        setUserdetails(proposition.user)



 
  }).catch(({response}) => {

  })
  
  
 }, []);
  useEffect(() => {
    axios.get(`showPropositionvoted/${storedUserData.id}/${id}`)
    .then(({data}) => {
   
        setData(data[0]);     
        if(data[0].length>0){
          setvote(true)
        }
      
        setnbvote(data[1])
      
    }).catch(({response}) => {
    
    })
  }, []);
  const votsubmit = () => {
  


  if(  storedUserData==null )
  { 
     navigate("/login",{replace:true});
  }
  else  {
    setvote(!vote)
    if(vote!=true){
      setnbvote(nbvote+1)
    }else{
      setnbvote(nbvote-1)
    }
    try {  
       const formData = new FormData();
        formData.append('user_id' , storedUserData.id)
        formData.append('proposition_id' , id)

      const response =  axios.post(`vote/store`,formData,
          {
           
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
      )
      .then((response) => {



      })

     
  } catch (err) { 
      if (!err?.response) {
      } else if (err.response?.status === 409) {
      } else {
 
      }
  }

   }
  };


const textt =""

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <>
    <NavBar/>
     <div className="main-banner container-fluid " id='Home' >
          <div className="d-table-proposition">
                <div className="blog-card">
                {proposition.user!=null?proposition.user.image? <div className="avatar">
                                                                            <img src={"https://dev.futurevisions.tn/"+proposition.user.image} alt="User Avatar"/>
                                                                          </div>:   <div id="" className="text-center mb-4 avatar2">
                                              <span>{proposition.user.name[0].toUpperCase()}</span>
                                                              </div> :'' } 
                                                          
                      
                        <div className="content">
                          <h2 className="name">{proposition.user==null?"":proposition.user.name}</h2>
                        </div>
                        
                </div>
                <div className={franc(proposition.title)!="arb"?'proposition-content ':'proposition-content-arabe '}   >
                    <div  className='proposition-title'>
                      <div>
                      <h4>{proposition.title} </h4>
                      <p><span> {franc(proposition.title)!="arb"?"Sujet":"الموضوع"} :</span> <span>{proposition.sujet==null?"":proposition.sujet.title}</span></p>

                      </div>
              
                  
                      {voteDate < date ?  <div onClick={votsubmit} className='vote-button'  >              
                        {data.length !=0 || vote?
                          <svg  xmlns="http://www.w3.org/2000/svg" width="20" height="20" fillRule="#1F284F" className="bi bi-hand-thumbs-up-fill mx-1" viewBox="0 0 16 16">
                          <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z"/>
                          </svg> :<svg  xmlns="http://www.w3.org/2000/svg" width="20" height="20" fillRule="#1F284F" className="bi bi-hand-thumbs-up mx-1" viewBox="0 0 16 16">
                          <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
                          </svg>
                         } <div>{nbvote}  {nbvote>1 ?'Votes':"Vote"} </div>
                      </div> :''}
                     

                    </div>

                    {proposition.type=="Blog"? <div className="">
                    <p  className=' m-4'>{        <div
      dangerouslySetInnerHTML={{__html: proposition.text}}
    />}</p>
                    
                     
                    </div>:proposition.type=="Infographie"? <div className="Infographie">
                    <div className='InfographieImage'>   
                        <img src={"https://dev.futurevisions.tn/"+proposition.image } className=' infographieImage  mb-4'/>


                            </div>  
                      <p>
                        <span className="large-letter">
                        {capitalizeFirstLetter(proposition.description==null?textt.charAt(0):proposition.description.charAt(0))}
                        </span>
                      {showFullText ? proposition.description==null?textt.charAt(0):proposition.description.slice(1) :proposition.description==null?textt.charAt(0):proposition.description.slice(1) .slice(0, 750) + '...'}
                        </p>
                     
                    </div>: <div className="Debat">
                    <div className='videoDebat'>   
                            <iframe  height={350} width={550} src={"//www.youtube.com/embed/"+proposition.url+"?rel=0"} scrolling="yes" sandbox="allow-same-origin allow-scripts"  ></iframe>
                            </div>   
                      <p>
                        <span className="large-letter">
                        {capitalizeFirstLetter(proposition.description==null?textt.charAt(0):proposition.description.charAt(0))}
                        </span>
                      {showFullText ? proposition.description==null?textt.charAt(0):proposition.description.slice(1) :proposition.description==null?textt.charAt(0):proposition.description.slice(1) .slice(0, 750) + '...'}
                        </p>
                      
                    </div>}
                   
                    <div className="icons-share">
                      <div className='mt-3'> <p> partager sur :</p></div>
                     
                      <div className='mx-3'>
                        <FacebookShareButton
                        url={`https://dev.futurevisions.tn/${id}`}
                        title={proposition.titre}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#1F284F" className="bi bi-facebook" viewBox="0 0 16 16">
                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                        </svg></FacebookShareButton>
                      </div>
                      
                      <div className='mx-3'>
                      <TwitterShareButton
                        url={`https://dev.futurevisions.tn/${id}`}
                        title={proposition.titre}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#1F284F" className="bi bi-twitter" viewBox="0 0 16 16">
                          <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                          </svg></TwitterShareButton>
                      </div>   
                    
                    </div>
                </div>
          </div>
          <Footer/>
     </div>
   
     </>
  )
}
