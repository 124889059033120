

import React, { Component } from 'react';
import autosize from 'autosize';
import axios from '../../../api/axios';


class VideoCourtProposition extends Component {
  state = {
    selectedOption: '',
    storedUserData :"",
     formFields: {
       title: '',
       description: '',
       url:''
   },
   sujet: [],
   sujetdesc:'',
};
    componentDidMount(){
      this.storedUserData =JSON.parse(localStorage.getItem('userData'));

       this.textarea.focus();
       autosize(this.textarea);

       axios.get(`sujet`)
       .then(res => {
         const sujet = res.data.sujet;
         this.setState({ sujet });
       
  
       }).catch(({response}) => {
       
  
     })
    }
    handleOptionChange = (e) => {
      const index = e.target.selectedIndex;
      const el = e.target.childNodes[index]
      const option =  el.getAttribute('id'); 
      const desc =  el.getAttribute('value'); 

        this.setState({
          selectedOption:option
        });
        this.setState({
          sujetdesc:desc
        });
    }
  
    urlChangeHandler = (e) => {
      let formFields = Object.assign({}, this.state.formFields);
      formFields.url = e.target.value;
      this.setState({formFields});
    }
    titleChangeHandler = (e) => {
      let formFields = Object.assign({}, this.state.formFields);
      formFields.title = e.target.value;
      this.setState({formFields});
    }
    descriptionChangeHandler = (e) => {
      let formFields = Object.assign({}, this.state.formFields);
      formFields.description = e.target.value;
      this.setState({formFields});
    }
   
 onSubmit = (e) => {
  e.preventDefault();
  try {
    const response =  axios.post(`envoyer_proposition`,
        JSON.stringify({  title:this.state.formFields.title,
          description:this.state.formFields.description,
          user_id:this.storedUserData.id,
          type:"Court",
          url:this.state.formFields.url,
          sujet_id:this.state.selectedOption
       }),
        {
         
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true
        }
    )
    .then((response) => {

      window.location.reload();

    })

   
} catch (err) {
    if (!err?.response) {
    } else if (err.response?.status === 409) {
    } else {
    }
}


}
    render(){
      const style = {
                maxHeight:'750px',
                minHeight:'450px',
                  resize:'none',
                  padding:'9px',
                  fontSize:'15px'};
        return (
          <div className='forum'>
          <form  onSubmit={this.onSubmit}  method="post">
          <div className="form-group  labellogin lienDrive">
            <label for="url">Sujet :</label>
            <p >Vous trouvez ci-dessous la liste des sujets correspondants à chaque catégorie de compétition.</p>
                          <select 
                           ref={c=>this.textarea=c}
                          className="form-control"  onChange={this.handleOptionChange}>
                          <option disabled selected>Choisissez un sujet</option>

              {       this.state.sujet.length != 0 && (this.state.sujet.map((sujet,index) => {
                                                          return    <option id={sujet.id}  value={sujet.description}> {sujet.title}</option>
 
                                                         
                                                          }))}

              </select>
              {this.state.sujetdesc ? <div className='mt-3   sujetdescription'>
                                                    
                                                    {this.state.sujetdesc}</div>
                                              
                                          :''} 
          </div>
          <div className="form-group  labellogin lienDrive">
            <label for="url">Lien Drive</label>
            <p >Veuillez insérer ci-dessous le lien drive correspondant à votre vidéo dont la durée doit être de 10 minutes.</p>
            <input type="text"
              
             className="form-control"
              placeholder="Exemple: https://drive.google.com/file/d/exemple/exemple" 
              id="url"
              name='url'
              required={true} 
              data-error="Please enter url" 
              value={this.state.formFields.url}
              onChange={this.urlChangeHandler}
              />
          </div>
          <div className="form-group labellogin lienDrive ">
            <label for="title " className="labellogin">Title</label>
            <input 
            
            type="text" 
            className="form-control" 
            placeholder="Title" 
            name='title'
            id="title"
            required={true} 
            data-error="Please enter title" 
            value={this.state.formFields.title}
            onChange={this.titleChangeHandler}

            
            />          </div>
          <div className="form-group labellogin   lienDrive">
          <label for="description">Description</label>

            <div>
            <textarea className="form-control"
 name='description'
 id="description"
 required={true} 
 data-error="Please enter description" 
 value={this.state.formFields.description}
 onChange={this.descriptionChangeHandler}



            style={style} 
         
            placeholder="type some text"
            rows={1} defaultValue=""/>
          </div>          </div>
     


         
          <button  type='submit' class="btn  mb-2">Envoyer</button>
    
          
          
        </form>
              </div>
        );
    }
}export default VideoCourtProposition;