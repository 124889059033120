import React from 'react';
import { Link } from 'react-router-dom';
 
class NotFound extends React.Component {
    render(){
        return (
            <div className="error-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <h1>4<span>0</span><b>4</b>!</h1>
                        <h3>Oops ! Page introuvable</h3>
                        <p>
La page que vous recherchez n'a pas pu être trouvée.</p>
                        <Link 
                            to="/" 
                            className="btn  not-found-btn"
                            onClick={this.toggleNavbar}
                        >
                           Retour à la page d'accueil
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default NotFound;