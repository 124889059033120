import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { Component, useEffect, useRef, useState } from 'react';
import  axios from  '../../api/axios'
import { Link } from 'react-router-dom';
import UpdateProgressBar from '../Common/UpdateProgressBar';


export const KidsCrsoul = () => {
    const customeSlider = useRef();
    const [data, setData] = useState([]);

  const [content, setContent] = useState('');

    const settings = {
    
      
      dots: true,
            infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 1,
      responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
    };
    const previous = () => {
      customeSlider.current.slickNext();
    };
  
    const next = () => {
      customeSlider.current.slickPrev();
    };
    useEffect(() => {
    

      async function getProposition() {
         //katb3t fa9at parameter wa7ed li hwa route li defininah f api.php
        
          axios.get(`getresource`)
          .then(res => {
            const post = res.data.resource;
            setData(post);
           
          })
            
  
      }
      getProposition() 
     
    }, []); 
  
    

  return (
    <div  className=''>

    



 <div>

      <Slider {...settings} ref={customeSlider} className="Slider">
      {      data.length != 0 && (data.map((post,index) => {
                                                          return  post.type=="kids"? <div className='slide-item'><iframe width="352" height="250" src={"//www.youtube.com/embed/"+post.url+"?rel=0"} frameBorder="0" allowFullScreen></iframe></div>
                                                          :''
 
                                                         
                                                          }))}
     
 
      </Slider> </div>
      <div className='d-flex align-items-center custom-flex-containe '>
        <div  className="custom-center-content" > 
    <a   onClick={previous} class="previous round  mx-2" style={{ background: '#2C92D0' ,color:'white'}}>&#8249;</a>
<a  onClick={next} class="next round" style={{ background: '#2C92D0' ,color:'white'}}>&#8250;</a>
</div>
    </div>

</div>
  )
}
