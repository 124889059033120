import React, { Component, useState, useEffect, useRef, } from "react";
import axios from "../../../../api/axios";
import { Link ,useLocation, useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';





function BlogProposition(props) {
  const storedUserData =JSON.parse(localStorage.getItem('userData'));
  const  [text ,settext]=useState();
  const [sujet, setSujet] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const  [title ,settitle]=useState('');
  const inputRef = useRef(null);
  const editorRef = useRef(null);
  const navigate=useNavigate();
  const [sujetapi, setSujetapi] = useState("");
  const [id, setId] = useState("");
  const  [sujetdesc ,setsujetdesc]=useState('');
  const {info} = props;
  useEffect(() => {


    return () => {
      // Clean up the editor instance
      if (editorRef.current) {
        editorRef.current.destroy();
      }
    };
  }, []);
  useEffect(() => {
    inputRef.current.focus();
  }, []);
  useEffect(() => {
    setSelectedOption(info.sujet.id)
  setId(info.id)
  settitle(info.title)
  setSujetapi(info.sujet.title)
  settext(info.text)
    axios.get(`sujet`)
    .then(res => {
      const sujet = res.data.sujet;
      setSujet( sujet );
  
  
    }).catch(({response}) => {
    
  
  })
  
      }, []); 


  const handleOptionChange = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index]
    const option =  el.getAttribute('id'); 
    const desc =  el.getAttribute('value'); 
    setSelectedOption(option)
    setsujetdesc(desc)
    
  }   
  const handleEditorChange = (content, editor) => {
    const extractedContent = editor.getContent(); // Extract the content using getContent method
    settext(extractedContent)

  };

  useEffect(() => {
  
    axios.get(`sujet`)
    .then(res => {
      const sujet = res.data.sujet;
      setSujet( sujet );
  

    }).catch(({response}) => {
    

  })

      }, []); 
      const  onSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append('title' , title)
        formData.append('description', "description")
        formData.append('type', "Blog")
        formData.append('text', text)
        formData.append('sujet_id', selectedOption )
  
        try {
          const response =  axios.post(`modifer_proposition/${id}`,
   formData,
              {
               
                  headers: { 'Content-Type': 'multipart/form-data' },
                  withCredentials: true
              }
          )
          .then((response) => {
            window.location.reload();
      
          })
      
         
      } catch (err) {
          if (!err?.response) {
          } else if (err.response?.status === 409) {
          } else {
          }
      }
      
      
      
     
    }
  return (

    <form  onSubmit={onSubmit}  method="post" encType='multipart/form-data'>
          <div  className='propostion-title-etat  ' > 
                      <h1 className='mx-3  mb-4'>{title}</h1>
              
               </div>
               <div  className='propostion-title-etat  ' > 
               <h6 ><span >Sujet: </span>{sujetapi}</h6>
              
               </div>
    <div className="form-group  labellogin lienDrive">
      <label for="url">Sujet :</label>
      <p >Vous trouvez ci-dessous la liste des sujets correspondants à chaque catégorie de compétition.</p>
                    <select   ref={inputRef}  className="form-control"  onChange={handleOptionChange}>
                    <option disabled selected>Choisissez un sujet</option>

        {     sujet.length != 0 && (sujet.map((sujet,index) => {
                                                    return    <option id={sujet.id} value={sujet.description}> {sujet.title}</option>

                                                   
                                                    }))}

        </select>
        {sujetdesc ? <div className='mt-3   sujetdescription'>
                                                    
                                                    {sujetdesc}</div>
                                              
                                          :''}
    </div>

  
    <div className="form-group labellogin lienDrive ">
      <label for="title " className="labellogin">Title</label>
      <input 
     
      type="text" 
      className="form-control" 
      placeholder="Title" 
      name='title'
      id="title"
      required={true} 
      data-error="Please enter title" 
      onChange={(e) => settitle(e.target.value)}
      value={title}

      
      />
    </div>
    <div className="form-group labellogin lienDrive ">
    <p >Veuillez noter que le blog  ne doit pas dépasser 40 lignes.</p>

    <Editor
      onInit={(evt, editor) => (editorRef.current = editor)}
      initialValue={info.text}

      apiKey="f51er4q4rryf5gdauh5d9mclc0p5ar1g7yra5ddrbw4g3d5q"
      init={{
        height: 500,
        plugins: '',
        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link   table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',

      }}
      onChange={handleEditorChange}
    />
    </div>
 


   
    
    
    <button  type='submit' class="btn  mb-2">Modifier</button>

  </form>
   
  );
     
 }export default BlogProposition;