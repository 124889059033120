import { useRef, useState, useEffect, useContext } from 'react';
import axios   from "../../api/axios.js";
import useAuth from '../../hooks/useAuth.js';
import logo from '../../assets/images/Fichier 3icon gallery.png';
import { Link ,useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../context/AuthProvider.js';


const LOGIN_URL = '/login';
function ResetPassword() {
    const { userdata, login } = useContext(AuthContext);

   const { setAuth } = useAuth();

    const navigate=useNavigate();
    const location =useLocation();
    const from=location.state?.from?.pathname||"/";


    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
        
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.get(`reset_password/${user}`,
               
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            ) .then((response) => {
                navigate(`/ResetcCrypCode/${user}`,{replace:true});
                
              });
       
           
                
        } catch (err) {
            setErrMsg('Veuillez vérifier attentivement l\'adresse e-mail que vous avez entrée et réessayer.')

        }
    }
  return (
<div className="content-login  row">
                 <div className='logintitle   col-lg-6 '>
                    <div className='logintitle-minisection '>
                 <img src={logo}className='logoImagelogin'/>
            





                    <h1>Bienvenue de retour sur <b>Youth lucc!</b> </h1>
                    <div className="luucshapelogin">
                        <img src={require("../../assets/images/abc-removebg-preview.png")} className=" luucshape" alt="shape4" />
                    </div> 
                    </div>            
                    </div>
                    <div className='loginsection col-lg-4 '>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

                        <h4>Trouvez votre compte</h4>
                        <p>Veuillez entrer votre adresse e-mail pour rechercher votre compte.</p>
                        <form action="#" method="post" onSubmit={handleSubmit}>
                                    <div className="form-group labellogin">
                                    <label htmlFor="email">Email</label>
                                    <input 
                                    className="form-control" 
                                    type="email"
                                    id="email"
                                    ref={userRef}
                                    autoComplete="off"
                                    onChange={(e) => setUser(e.target.value)}
                                    value={user}
                                    required
                                    
                                    
                                    />
                                    </div>
                                    {/* <div className="form-group labellogin  ">
                                    <label htmlFor="password " className="labellogin">Mot de passe</label>
                                    <input 
                                    className="form-control" 
                                    type="password"
                                    id="password"
                                    onChange={(e) => setPwd(e.target.value)}
                                    value={pwd}
                                    required
                                    />
                                    </div>
                                     */}
                                    {/* <div className="d-sm-flex mb-4 align-items-center">
                                    <span className="ml-auto"><Link to='/resetpassword' className="forgot-pass">Mot de passe oublié ?




                    </Link></span> 
                                    </div> */}


                                

                                    <button className="btn  mb-2 w-100" type='submit'>Continuer</button>

                                    
                            </form>
                            <div>
       <p className='signupOrSignin'>

       <Link  to='/login' >Ce connecter avec le mot de passe</Link> 
       </p></div>
       <div className='logominidev'>
       <img src={logo}className='logomini'/>

       </div>
        
                    </div>
                

                                </div>
  )
}
export default ResetPassword;