import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { Component, useEffect, useRef, useState } from 'react';
import  axios from  '../../api/axios'
import { Link } from 'react-router-dom';
import UpdateProgressBar from '../Common/UpdateProgressBar';
import UpdateProgressBarvote from '../Common/UpdateProgressBarVote';

import { franc }from 'franc';

import image from '../../assets/images/jeune-belle-femme-pull-chaud-rose-aspect-naturel-souriant-portrait-isole-cheveux-longs.jpg'


export const ParticipantCrsoul = () => {
    const customeSlider = useRef();
    const [data, setData] = useState('');
    const [date, setdate] = useState('');
    const [targetDate, settargetDate] = useState('');
    const [voteDate, setvoteDate] = useState('');

     const targetDateprotected = new Date('2053-06-10T12:30:59');
  const [content, setContent] = useState('');

    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
    };
    const previous = () => {
      customeSlider.current.slickNext();
    };
  
    const next = () => {
      customeSlider.current.slickPrev();
    };

  
    
  useEffect(() => {
    if (date >= targetDate) {
      setContent()
    } else {

    }
  }, [date, targetDate]);
  useEffect(() => {
    async function getProposition() {
      //katb3t fa9at parameter wa7ed li hwa route li defininah f api.php
       await axios.get(`gettemps`)
       .then((temps) => {


        const dateObj = new Date(temps.data.temps[0].temps_lancement_competition);
        const dateObjvote = new Date(temps.data.temps[0].temps_lancement_vote);

        settargetDate(dateObj)
    
        setvoteDate(dateObjvote)
       }).catch(({response}) => {
   
       })

         

   }
   getProposition() 
  
 }, []); 
 useEffect(() => {
  const currentDate = new Date();
  setdate(currentDate)

  async function getProposition() {

await axios.get(`showAcceptedProposition`)
      .then(({data}) => {
 
          setData(data);     
      }).catch(({response}) => {
  
      })

   

        

  }
  getProposition() 
 
}, []); 
  return (
    <div  className='participatmain' id='Parti'>

    
 <h2 className='text-center m-5 '>Participants gallery
</h2>


    {  targetDate < date ? <div>
      
      <UpdateProgressBarvote targetDate={voteDate}/>
     
                                       
      <Slider {...settings} ref={customeSlider} className="Slider">
      {      data.length != 0 && (data.map((data,index) => {
                                                          return    <><div className="card participant-card">
                                                          <div className='participant-info-card'>
                                                          {data.user.image? <div className="avatar">
                                                                            <img src={"https://dev.futurevisions.tn/"+data.user.image} alt="User Avatar"/>
                                                                          </div>:   <div id="avatarcarouselparticipant" className="text-center mx-auto mb-4">
                                              <span>{data.user.name[0].toUpperCase()}</span>
                                                              </div>  } 
                                                          
                                                                       
                
                                                            <div>
                                                            <h5 className="name">{data.user.name}</h5>
                
                                                            </div>
                                                          </div>
                                                          <div className={franc(data.title)!="arb"?" card-content":" card-content-arabe"}>
                                                          <h6 className='card-titre'><p>{data.title}</p></h6>  
                                                            <p className='le-sujet'><span> {franc(data.title)!="arb"?"Sujet":"الموضوع"} :</span> <span>{data.sujet.title}</span></p>
                                                                         
                                                                          <div className="card-content-description">
                                                                            <p className="description">{data.type=="Blog"?    <div  dangerouslySetInnerHTML={{__html: data.text }}></div>  :data.description}</p>
                                                                          </div>
                                                                          <div className='details-btn'>
                                                                           <Link  to={`/Propositiondetails/${data.id}`}><span >Details</span></Link> 
                                                                            </div>
                                                                        </div> </div>
 </> 
                                                         
                                                          }))}
     
 
      </Slider>  <div className='d-flex '>
        <div className='custom-center-content mt-1'>
        <a   onClick={previous} class="previous round  mx-2" style={{ background: '#2C92D0' ,color:'white'}}>&#8249;</a>
<a  onClick={next} class="next round" style={{ background: '#2C92D0' ,color:'white'}}>&#8250;</a>
    
        </div>

    </div></div>: <UpdateProgressBar targetDate={targetDate}/>}

</div>
  )
}
