import React, { useEffect, useRef, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import axios from '../../../../api/axios';

function Infographie(props) {
 const storedUserData =JSON.parse(localStorage.getItem('userData'));
 const inputRef = useRef(null);
 const [data, setData] = useState('');
 const {info} = props;
 useEffect(() => {
   inputRef.current.focus();
 }, []);
  const navigate=useNavigate();
  const  [title ,settitle]=useState('');
  const [ description ,setdescription]=useState('');
  const [value, setValue] = useState("");
  const [height, setHeight] = useState("auto");
  const [image, setImage] = useState([]);
  const [imagelocale, setImageLocale] = useState();
  const [sujet, setSujet] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [id, setId] = useState("");
  const [sujetapi, setSujetapi] = useState("");
  const  [sujetdesc ,setsujetdesc]=useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
    setHeight("auto");

    const newHeight = event.target.scrollHeight;
    setHeight(newHeight);
  };


  useEffect(() => {
    setSelectedOption(info.sujet.id)
  setId(info.id)
  settitle(info.title)
  setdescription(info.description)
  setImageLocale("https://dev.futurevisions.tn/"+info.image)
  setSujetapi(info.sujet.title)
    axios.get(`sujet`)
    .then(res => {
      const sujet = res.data.sujet;
      setSujet( sujet );
  
  
    }).catch(({response}) => {
    
  
  })
  
      }, []); 



const  onSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        formData.append('title' , title)
        formData.append('description', description)
        formData.append('type', "Infographie")
        formData.append('image', image)
        formData.append('sujet_id', selectedOption )
  
        try {
          const response =  axios.post(`modifer_proposition/${id}`,
   formData,
              {
               
                  headers: { 'Content-Type': 'multipart/form-data' },
                  withCredentials: true
              }
          )
          .then((response) => {
            window.location.reload();
      
          })
      
         
      } catch (err) {
          if (!err?.response) {
          } else if (err.response?.status === 409) {
          } else {
          }
      }
      
      
      
     
    }


const handleFileChange = (e) => {
  const file = e.target.files[0];
  const reader = new FileReader();
  setImage(file)
  reader.onloadend = function () {
    setImageLocale(reader.result);
  };
  if (file) {
    reader.readAsDataURL(file);
  }
};
const     handleOptionChange = (e) => {
  const index = e.target.selectedIndex;
  const el = e.target.childNodes[index]
  const option =  el.getAttribute('id'); 
  const desc =  el.getAttribute('value'); 
  setSelectedOption(option)
  setsujetdesc(desc)
}
        return (
          <div className='forum'>
          <form onSubmit={onSubmit}   method="post" encType='multipart/form-data' >
          <div  className='propostion-title-etat  ' > 
                      <h1 className='mx-3  mb-4'>{title}</h1>
              
               </div>
               <div  className='propostion-title-etat  ' > 
               <h6 ><span >Sujet: </span>{sujetapi}</h6>
              
               </div>
          <div className="form-group  labellogin lienDrive">
            <label for="url">Sujet :</label>
            <p >Vous trouvez ci-dessous la liste des sujets correspondants à chaque catégorie de compétition.</p>
                          <select             ref={inputRef} className="form-control"  onChange={handleOptionChange}>
                          <option disabled selected>Choisissez un sujet</option>

              {       sujet.length != 0 && (sujet.map((sujet,index) => {
                                                          return    <option id={sujet.id} value={sujet.description}> {sujet.title}</option>
 
                                                         
                                                          }))}

              </select>
              {sujetdesc ? <div className='mt-3   sujetdescription'>
                                                    
                                                    {sujetdesc}</div>
                                              
                                          :''}
          </div>

          <div className="form-group  labellogin lienDrive">

            <label for="infographie">Infographie</label>
            
            <p >Veuillez sélectionner l'image correspondant à votre œuvre en cliquant sur le bouton ci-dessous</p>
         
         <div className='infographieImageDiv'>
         <img src={imagelocale } className=' infographieImage  mb-4'/>

         </div>
            <input type="file"  
 onChange={handleFileChange}            
  name="image" 
         
            className="form-control  mb-4" /> 
            
          </div>
          <div className="form-group labellogin lienDrive ">
            <label for="title " className="labellogin">Title</label>
            <input 
 
            type="text" 
            className="form-control" 
            placeholder="Title" 
            name='title'
            id="title"
            required={true} 
            data-error="Please enter title" 
            value={title}
            onChange={(e) => settitle(e.target.value)}

            
            />
       
          </div>
          <div className="form-group labellogin   lienDrive">
          <label for="description">Description</label>

            <div>
     
            <textarea
    name='description'
    id="description"
    required={true} 
    data-error="Please enter description" 
    placeholder="type some text"
    rows={1} defaultValue=""
    className="form-control"
    onChange={(e) => setdescription(e.target.value)}
    value={description}

      onInput={handleChange}
      style={{ height , resize:'none',
      padding:'9px',
      fontSize:'15px'}}
    />
           </div>          </div>
     


         
          
          
           <button  type='submit' class="btn  mb-2">Modifier</button>

        </form>
              </div>
        );
    
}export default Infographie;