import React from 'react'
import Owldemo1 from './Owldemo1'
import { KidsCrsoul } from './KidsCrsoul'

export const KidsLucc = () => {
  return (
    <div className='youtlucc kidsluuc ' id='KidsLUCC'>
        
          <h2 className='text-center  m-5  '>KIDS-LUCC</h2>
          {/* <div className="shape1">
         </div> */}
         <div className="shape2 ">
         </div>
         <div className="shape3 ">
         </div>
         <div className="shape4">
         </div>
    <div class="container">
 
                 
<KidsCrsoul/>   

</div>

      </div>
  )
}
