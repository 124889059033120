import React from 'react';
import { PopupMenu } from "react-simple-widgets";
import { Link ,useLocation, useNavigate } from 'react-router-dom';
import { useContext ,useEffect,useState} from 'react';
import axios  from '../../api/axios';

function ProfileAvatar() {

    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    const navigate=useNavigate();
    const [email, setEmail] = useState("");
    const [name, setUser] = useState("");
    const [image, setImage] = useState("");

    const logout = () => {
        localStorage.removeItem('userData');
        navigate('/login');
    
      };
      useEffect(() => {
        async function getProfile() {
            await axios.get(`getUserData/${storedUserData.id}`)
            .then(({data}) => {
                setUser(data.user.name)
                setImage("https://dev.futurevisions.tn/"+data.user.image)
                setEmail(data.user.email)
         
            }).catch(({response}) => {
            
            })
  
              
  
        }
        getProfile() 
       
      }, []); 
     
  return (
    <div  className='composant'>


  <PopupMenu>
  {image!="https://dev.futurevisions.tn/null"  ?
                            
                            <div id="moni-circle-avatar" className="text-center mx-auto mb-4">
                            <img src={image }className="avatarmini"/> 
                          </div>
                       :
                           
                           <div id="caract" className="text-center mx-auto mb-4">
                           <span>{storedUserData.name[0].toUpperCase()}</span>
                         </div>
                   } 
  

  <div className="card text-start">
    <div className="card-body px-4 py-4">

      <div className='d-flex  justify-content-start '>

      {image!="https://dev.futurevisions.tn/null"  ?
                            
                            <div id="circle-avatar" className="text-center mx-auto mb-4">
                            <img src={image }className="avatarmini"/> 
                          </div>
                       :
                           
                       <div id="caract" className="text-center mx-auto mb-4">
                       <span>{storedUserData.name[0].toUpperCase()}</span>
               
                       </div>
                   } 
          
   


      <div className='d-flex flex-column justify-content-center ms-2 mb-3 profil-menu' > 
      <h6 className=" mb-0">{storedUserData.name}</h6>
      <p className=" mb-2 emailstyle">{storedUserData.email}</p>
      </div>
      

      </div>
   
  
        <div className="d-grid d-flex justify-content-center  profil-menu">
          <Link to="/profil">
          <small className='Gerer-profil-button'>Gérer  votre compte</small>
        
        </Link>
      </div>

   
      <div className="d-grid d-flex justify-content-right mt-3 button-logout profil-menu">

        <p className="logout-profil-button ms-1 " onClick={logout}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"/>
<path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"/>
        </svg> 
          <small className='ms-2'>Se déconnecter </small>
        </p>
      </div>
    </div>
  </div>
</PopupMenu>
    
  </div>
  );
  
  
}

export default ProfileAvatar;