import React from 'react'
import logo from '../../assets/images/Fichier 3icon gallery.png';
import { useRef, useState, useEffect  } from "react";

import { faCheck, faTimes, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link ,useLocation, useNavigate } from 'react-router-dom';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import axios   from "../../api/axios.js";

const USER_REGEX = /^[A-Za-z]+(?: [A-Za-z]+){0,3}$$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const AGE_REGEX = /^(1[5-9]|[2-9][0-9])$/;
const PHN_REGEX = /^\d{8}$|^216\d{8}$|^0\d{7}$/;
const REGISTER_URL = '/register';
export const SingUp = () => {

  const userRef = useRef();
    const errRef = useRef();


    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const [age, setAge] = useState('');

    const [validAge, setValidAge] = useState(false);
    const [ageFocus, setAgeFocus] = useState(false);


    const [Ntel, setNtel] = useState('');

    const [validNtel, setValidNtel] = useState(false);
    const [NtelFocus, setNtelFocus] = useState(false);

    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);

    const [lastname, setLastname] = useState('');
    const [validlastname, setValidLastname] = useState(false);
    const [lastnameFocus, setlastnameFocus] = useState(false);


    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);

    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);
    const [csrfToken, setCsrfToken] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])


     useEffect(() => {
        setValidLastname(USER_REGEX.test(lastname));
    }, [lastname])

    useEffect(() => {
      setValidEmail(EMAIL_REGEX.test(email));
    }, [email])
     useEffect(() => {
      setValidNtel(    PHN_REGEX.test(Ntel));
    }, [Ntel])

    useEffect(() => {
      setValidAge(AGE_REGEX.test(age));
    }, [age])

    useEffect(() => {
        setValidPwd(PWD_REGEX.test(pwd));
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd, matchPwd])

    useEffect(() => {

      async function fetchCsrfToken() {
        const response = await axios.get('/csrf-token');
        setCsrfToken(response.data.csrfToken);
      }
      fetchCsrfToken();
    }, []);

    const navigate=useNavigate();

  const handleSubmit = async (e) => {

    e.preventDefault();
    // if button enabled with JS hack
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    const v3 = AGE_REGEX.test(age);
    const v4 = EMAIL_REGEX.test(email);
    if (!v1 || !v2  || !v3  || !v4) {
        setErrMsg("Entrée invalide");
        return;
    }
    try {
        const response = await axios.post(REGISTER_URL,
            JSON.stringify({  email: email,
              password: pwd,
              name:user,
              numerotel:Ntel,
              age:age, }),
            {
             
                headers: { 'Content-Type': 'application/json' ,
               },
                withCredentials: true
            }
        )
        .then((response) => {
          
          navigate('/login',{replace:true});
        })

        setSuccess(true);
        setUser('');
        setEmail('');
        setAge('');
        setPwd('');
        setMatchPwd('');
    } catch (err) {
  

        if (!err?.response) {
            setErrMsg('Pas de réponse du serveur');
        } else if (err.response?.status === 422) {
            setErrMsg('E-mail déjà pris');
        } else {
         
          setErrMsg('E-mail déjà pris');

            setErrMsg(err.response?.status )
        }
        errRef.current.focus();
    }
}


const [showPassword, setShowPassword] = useState(false);

const togglePasswordVisibility = () => {
  setShowPassword(!showPassword);
};
const [showPassword2, setShowPassword2] = useState(false);

const togglePasswordVisibility2 = () => {
  setShowPassword2(!showPassword2);
};
  return (

      <div className="singup">
      
              
            <div className="content-login  row">
              <div className='logintitle   col-lg-6 '>
                <div className='logintitle-minisection '>
              <img src={logo}className='logoImagelogin'/>
              <div className="luucshape">
                    <img src={require("../../assets/images/flag-removebg-preview.png")} className=" luucshape" alt="shape4" />
                </div> 
            
                <h1> <b>Youth lucc!</b> Rejoignez-nous maintenant !</h1>
                {/* <p>Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page</p> */}
                </div>            
                </div>
           <div className='loginsection col-lg-4 mt-3 '>
           <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

               <h4>Créer votre compte </h4>
          
            
               <form onSubmit={handleSubmit}>
       <div className=' justify-content-around'>
        <div className='row ms-1'>


        <div className="form-group labellogin col-md-6 ">
         <label  htmlFor="username">Nom et Prénom
         <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
         <FontAwesomeIcon icon={faTimes} className={validName || !user ? "hide" : "invalid"} />
         
         </label>
         <input  type="text"
                   id="username"
                   ref={userRef}
                   autoComplete="off"
                   onChange={(e) => setUser(e.target.value)}
                   value={user}
                   required
                   aria-invalid={validName ? "false" : "true"}
                   aria-describedby="uidnote"
                   onFocus={() => setUserFocus(true)}
                   onBlur={() => setUserFocus(false)}
                    className="form-control"
                    />
                          <p id="uidnote" className={userFocus && user && !validName ? "instructions" : "offscreen"}>
                   <FontAwesomeIcon icon={faInfoCircle} />
                   4 à 24 caractères.<br />
                   Doit commencer par une lettre.<br />
                    Nom Prenom : Foulan Foulan
               </p>
      
       </div>
       <div className="form-group labellogin col-md-6 ">
         <label htmlFor="username">Age
         <FontAwesomeIcon icon={faCheck} className={validAge ? "valid" : "hide"} />
         <FontAwesomeIcon icon={faTimes} className={validAge || !age ? "hide" : "invalid"} />
         
         </label>
         <input type="number"                        
              
                   autoComplete="off"
                   onChange={(e) => setAge(e.target.value)}
                   value={age}
                   required
                   aria-invalid={validAge ? "false" : "true"}
                   aria-describedby="uidnote"
                   onFocus={() => setAgeFocus(true)}
                   onBlur={() => setAgeFocus()}
                    className="form-control" 
             
                    id="age"/>
                         <p id="uidnote" className={ageFocus && age && !validAge ? "instructions" : "offscreen"}>
                   <FontAwesomeIcon icon={faInfoCircle} />
                   Veuillez entrer un âge valide compris entre 15 et 99 ans.
<br />
                 
               </p>
       </div>



        </div>
  
     
       </div>
       <div className="form-group labellogin  ">
         <label htmlFor="username">Numéro de téléphone
         <FontAwesomeIcon icon={faCheck} className={validNtel ? "valid" : "hide"} />
         <FontAwesomeIcon icon={faTimes} className={validNtel || !Ntel ? "hide" : "invalid"} />
         
         </label>
         <input type="number"                        
              
                   autoComplete="off"
                   onChange={(e) => setNtel(e.target.value)}
                   value={Ntel}
                   required
                   aria-invalid={validNtel ? "false" : "true"}
                   aria-describedby="uidnote"
                   onFocus={() => setNtelFocus(true)}
                   onBlur={() => setNtelFocus(false)}
                    className="form-control" 
                    
                    id="Ntel"/>
                 
                    <p  className='exempltel'>Exemple: 12345679</p>
                         <p id="uidnote" className={NtelFocus &&  Ntel && !validNtel? "instructions" : "offscreen"}>
                   <FontAwesomeIcon icon={faInfoCircle} />
                   Veuillez saisir un numéro de téléphone valide ( Tunisien ).
<br />
                 
               </p>
       </div>
   
       <div className="form-group labellogin ">
         <label for="username">Email
         <FontAwesomeIcon icon={faCheck} className={validEmail ? "valid" : "hide"} />
         <FontAwesomeIcon icon={faTimes} className={validEmail || !email ? "hide" : "invalid"} />
         
         
         </label>
         <input type="email"
            autoComplete="off"
                   onChange={(e) => setEmail(e.target.value)}
                   value={email}
                   required
                   aria-invalid={validEmail ? "false" : "true"}
                   aria-describedby="uidnote"
                   onFocus={() => setEmailFocus(true)}
                   onBlur={() => setEmailFocus(false)}
                    className="form-control" 
         
                    id="email"/>
                              <p id="uidnote" className={emailFocus && email && !validEmail ? "instructions" : "offscreen"}>
                   <FontAwesomeIcon icon={faInfoCircle} />
                   Veuillez entrer une adresse e-mail valide au format.<br />
                    nom@example.com.<br />
               </p>
       </div>
       <div className="form-group labellogin  ">
         <label for="password " className="labellogin">Mot de passe
         <FontAwesomeIcon icon={faCheck} className={validPwd ? "valid" : "hide"} />
                   <FontAwesomeIcon icon={faTimes} className={validPwd || !pwd ? "hide" : "invalid"} />
         
         </label>
         <div  className="form-controlmotdepasse">
         <input   type= {showPassword ? "text":"password"}
                   id="password"
                   onChange={(e) => setPwd(e.target.value)}
                   value={pwd}
                   required
                   aria-invalid={validPwd ? "false" : "true"}
                   aria-describedby="pwdnote"
                   onFocus={() => setPwdFocus(true)}
                   onBlur={() => setPwdFocus(false)}
                   className="form-control-motdepasse-input"
                    
                    />
<span onClick={togglePasswordVisibility} className='lesapan'>
      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
    </span></div>
<p id="pwdnote" className={pwdFocus && !validPwd ? "instructions" : "offscreen"}>
                   <FontAwesomeIcon icon={faInfoCircle} />
                   8 à 24 caractères.<br />
                   Doit inclure des lettres en majuscules et en minuscules, un chiffre et un caractère spécial.<br />
                   Caractères spéciaux autorisés : <span aria-label="exclamation mark">!</span> <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span> <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
               </p>
       </div>

       <div className="form-group labellogin mb-4  ">
         <label htmlFor="confirm_pwd"className="labellogin">Confirmer mot de passe</label>
         <div  className="form-controlmotdepasse">
         <input 
         
         type= {showPassword2 ? "text":"password"}
         id="confirm_pwd"
         onChange={(e) => setMatchPwd(e.target.value)}
         value={matchPwd}
         required
         aria-invalid={validMatch ? "false" : "true"}
         aria-describedby="confirmnote"
         onFocus={() => setMatchFocus(true)}
         onBlur={() => setMatchFocus(false)}
         className="form-control-motdepasse-input"
          />
          <span onClick={togglePasswordVisibility2} className='lesapan'>
      <FontAwesomeIcon icon={showPassword2 ? faEyeSlash : faEye} />
    </span></div>
            <p id="confirmnote" className={matchFocus && !validMatch ? "instructions" : "offscreen"}>
                   <FontAwesomeIcon icon={faInfoCircle} />
                   Doit correspondre au premier champ de saisie du mot de passe.
               </p>
       </div>
       
     

      
       
     <button class="btn  mb-2 w-100" disabled={!validName   || !validEmail  || !validPwd || !validMatch ? true : false}>S'inscrire </button>
<div>
      <p className='signupOrSignin'>
      Avez-vous déjà un compte ?
       <Link   to='/login '>Se connecter</Link> 
       </p>
       </div>

       <div className='logominidev'>
       <img src={logo}className='logomini'/>

       </div>
            </form>
        

           </div>
                       </div>
      
      </div>
  )
}


